.cart-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $black;
    opacity: 0.56;
    cursor: pointer;
  }

  .main-cart-content {
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 38rem;
    width: 100%;
    background: $white;

    .cart-footer {
      border-top: 1px solid $black;
      background: $brand-300;
      padding: 1.5rem;

      @include md {
        padding: 1.5rem 3rem 2.5rem;
      }
    }
  }

  .cart-item {
    margin-bottom: 1.5rem;

    .cart-item--image {
      min-height: 100%;
      width: 96px;
      background-position: center;
      background-size: cover;
    }
  }
}
