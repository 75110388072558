.main-product-content {
  max-width: 1440px;
  display: flex;
  margin: 0 auto;
  padding: 0 2rem;

  @include md-max {
    display: block;
  }

  @include small-xs {
    padding: 0 1.5rem;
  }

  .product-sidebar {
    display: flex;
    top: 6.5rem;
    min-width: 16rem;
    position: sticky;
    align-self: flex-start;
    justify-content: space-between;

    @include md-max {
      display: none;
    }
  }

  .product-images {
    display: flex;
    margin: 0 4rem;
  }

  .product-detail {
    position: sticky;
    top: 6.5rem;
    min-width: 24rem;
    align-self: flex-start;

    @include md-max {
      padding: 1.5rem 1em 0;
    }

    @include small-xs {
      padding: 1.5rem 0 0;
      min-width: 15rem;
      align-self: flex-start;
    }
  }
}