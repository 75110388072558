// ####################################### //
// ## COLORS.SCSS ## //
// ####################################### //

$light-weight: 200;
$regular-weight: 400;
$medium-weight: 500;
$semibold-weight: 600;
$bold-weight: 700;

$black: #000;
$white: #fff;

$black-900: #000000;
$black-800: #222222;
$black-700: #2b2b2b;
$black-600: #323232;
$black-500: #444444;
$black-400: #535353;
$black-300: #606060;
$black-200: #6c6c6c;
$black-100: #757575;

$gray-900: #797979;
$gray-800: #929292;
$gray-700: #a5a5a5;
$gray-600: #b9b9b9;
$gray-500: #c8c8c8;
$gray-400: #d6d6d6;
$gray-300: #e2e2e2;
$gray-200: #ececec;
$gray-100: #f4f4f4;
$gray-50: #fbfbfb;

$brand-900: #f7fbfd;
$brand-800: #f7fbfd;
$brand-700: #6610f2;
$brand-600: #f7fbfd;
$brand-500: #f7fbfd;
$brand-400: #f7fbfd;
$brand-300: #f4fafb;
$brand-200: #f7fbfd;
$brand-100: #f7fbfd;

$info-900: #01408f;
$info-800: #026dd6;
$info-700: #0284fe;
$info-600: #4ba7fe;
$info-500: #83c3fe;
$info-400: #b3daff;
$info-300: #dceeff;
$info-200: #eef7ff;
$info-100: #f8fbff;

$danger-900: #c50901;
$danger-800: #dd2018;
$danger-700: #ee3e37;
$danger-600: #fe6963;
$danger-500: #ffa09c;
$danger-400: #ffc3c0;
$danger-300: #ffe1e0;
$danger-200: #fff2f1;
$danger-100: #fffaf9;

$success-900: #0e7c5f;
$success-800: #229b7b;
$success-700: #3bbc9a;
$success-600: #58d0b0;
$success-500: #83eacf;
$success-400: #bfffee;
$success-300: #dffff6;
$success-200: #f1fffb;
$success-100: #f9fffd;

$warning-900: #fa912c;
$warning-800: #faa12c;
$warning-700: #fdb94c;
$warning-600: #ffce6d;
$warning-500: #ffdc89;
$warning-400: #ffebad;
$warning-300: #fff9d1;
$warning-200: #fffbe3;
$warning-100: #fffef6;
