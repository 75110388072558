.checkout {
  &__line-item-image {
    @extend .w-56, .h-64, .object-cover;
  }
}

.custom-container {

  .checkout-error {
    margin: 0;
    padding-bottom: 6px;
  }

  .checkout-summary {
    position: sticky;
    top: 15%;
  }
}
