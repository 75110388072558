// ######################################## //
// ## Body.SCSS ## //
// ######################################## //

html {
  font-size: 16px;
}

body {
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100vw;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  color: $black;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}

::selection {
  background: $black;
  color: $white;
}

:focus {
  outline: 3px auto $gray-500;
  box-shadow: none !important;
}

p {
  margin-bottom: 0;
}

input,
.dropdown {
  height: 3rem !important;
  padding: 0 1rem !important;
  border: 1px solid $gray-400 !important;
  transition: border-color 0.3s ease-in-out;
  border: none;

  &.borderbottom {
    border: none !important;
    border-bottom: 1px solid $gray-400 !important;
  }

  &::placeholder {
    color: $gray-500 !important;
  }

  &:focus {
    border-color: $black;
  }
}

.dropdown--item {
  border-color: $gray-400;
  background-color: $white;

  &:focus {
    background-color: $gray-100;
    outline: none !important;
  }

  // &:focus {
  //   border-color: $black;
  // }
}

textarea {
  height: 8rem !important;
  padding: 1rem !important;
  border: 1px solid $gray-400;
  transition: border-color 0.3s ease-in-out;
  resize: none;

  &::placeholder {
    color: $gray-500 !important;
  }

  &:focus {
    border-color: $black;
  }
}

button {
  background: $brand-700;
  border: none;

  &:focus {
    outline: 3px auto $gray-500;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 60%;
  }
}

label {
  margin-bottom: 0;
}

// .form-control {
//   font-weight: $regular-weight;
//   font-size: 0.875rem;
//   background-color: $gray-100;
//   border-color: $gray-400;
//   border-radius: 0.5rem;

//   &:focus {
//     box-shadow: none !important;
//     border-color: $brand-700;
//   }

//   &::placeholder {
//     color: $gray-600;
//   }
// }

a {
  &:hover {
    text-decoration: none;
    @include transition;
  }

  &.text-decoration-underline {
    text-decoration: underline;
  }
}

// a {
//   color: $black-900;

//   &:hover {
//     color: $brand-700;
//   }
// }

.small-container {
  max-width: 1232px !important;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-left: auto;
  margin-right: auto;

  @include small-xs {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.custom-container {
  max-width: 1440px;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-left: auto;
  margin-right: auto;

  @include small-xs {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 8px;
  padding-left: 8px;
}

.row {
  margin-right: -8px;
  margin-left: -8px;
}

button.border.border-color-white,
a.border.border-color-white {
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.248) !important;
  }
}
