.about-container {
  padding: 1rem 3rem 0;
  max-width: 1600px;
  margin: 0 auto;

  @include small-xs {
    padding: 0;
  }

  .row {
    padding-bottom: 8rem;
    @include small-xs {
      padding-bottom: 2rem;
    }

    .row-content {
      padding-left: 3rem;
      padding-right: 3rem;
  
      @include small-xs {
        padding-left: 0;
        padding-right: 0;
      }

      .cjs-link {
        color: #2C7EA1;
        font-weight: bold;

        &:hover{
          color: darken(#2C7EA1, 10%);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .about-cjs {
    .about-doc {
      background: #2C7EA1;
      margin-right: 20px;
    }
  
    .about-dev {
      background-color: #fff;
      color: #2C7EA1;
      border: 1px solid #2C7EA1;
    }
  }

  .about-net {
    background-color: #29A38A;
  }

  .about-gb {
    background-color: #283341;
  }

  .about-blog {
    background-color: #254E81;
    width: 140px;
  }
}
