header {
  z-index: 10;
}

.header {
  padding: 1.5rem 3rem;
  background-color: transparent;
  border-bottom: 1px solid;
  border-color: transparent;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    filter 0.01s linear 0.2s;
  filter: invert(1);

  &.invert {
    background-color: #fff;
    border-color: #000;
    filter: none;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  }

  @include small-xs {
    padding: 1.25rem 1.5rem 1.25rem 1rem;
  }

  .logo-container {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include small-xs {
      position: static;
      transform: none;
    }
  }

  .logo {
    width: auto;
    height: 4rem;
  }

  .cart-count {
    right: -4px;
    top: -4px;
    max-width: 10px;
  }

  .login {
    position: relative;
    top: 0.5rem;
    margin-right: 1rem;
  }
}

.mobile-menu-inner {
  padding: 7rem 1.5rem;
}
