// ######################################## //
// ## MIXINS.SCSS ## //
// ######################################## //

// ----------------------------------
// Placeholder Mixin
// ----------------------------------
@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

// ----------------------------------
// Translate Mixin
// ----------------------------------
@mixin translate($h, $v) {
  -webkit-transform: translate($h, $v);
  -moz-transform: translate($h, $v);
  -ms-transform: translate($h, $v);
  -o-transform: translate($h, $v);
  transform: translate($h, $v);
}

// ----------------------------------
// Rotate Mixin
// ----------------------------------
@mixin rotate($x) {
  -webkit-transform: rotate($x);
  -moz-transform: rotate($x);
  -ms-transform: rotate($x);
  -o-transform: rotate($x);
  transform: rotate($x);
}

// Backface Visibility Mixin
@mixin backface-visibility {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

// Set Border radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// Set Tarnsiiton for a duration of 0.2s
@mixin transition($property: all, $time: 0.2s, $speed: ease-in-out) {
  -webkit-transition: $property $time $speed;
  -moz-transition: $property $time $speed;
  -ms-transition: $property $time $speed;
  transition: $property $time $speed;
}

// Set Box-Shadow
@mixin box-shadow($h1, $v1, $b1, $s1, $c1, $h2, $v2, $b2, $s2, $c2) {
  -webkit-box-shadow: $h1 $v1 $b1 $s1 $c1, $h2 $v2 $b2 $s2 $c2;
  -moz-box-shadow: $h1 $v1 $b1 $s1 $c1, $h2 $v2 $b2 $s2 $c2;
  box-shadow: $h1 $v1 $b1 $s1 $c1, $h2 $v2 $b2 $s2 $c2;
}

// Background Gradients
@mixin linear-gradient($dir, $from, $to) {
  background: $to;
  background: -moz-linear-gradient($dir, $from 0%, $to 100%);
  background: -webkit-linear-gradient($dir, $from 0%, $to 100%);
  background: -o-linear-gradient($dir, $from 0%, $to 100%);
  background: linear-gradient($dir, $from 0%, $to 100%);
}

// ----------------------------------
// Keyframes Mixin (Cross Browser)
// ----------------------------------
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

// ----------------------------------
// Animation (Cross Browser)
// ----------------------------------
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

// ----------------------------------
// Animation Play State(Cross Browser)
// ----------------------------------
@mixin animation-play-state($str) {
  -webkit-animation-play-state: #{$str};
  -moz-animation-play-state: #{$str};
  -ms-animation-play-state: #{$str};
  -o-animation-play-state: #{$str};
  animation-play-state: #{$str};
}

// ----------------------------------
// Media Query Mixin
// ----------------------------------
$iphone5-width: 350px;
$iphone6-width: 375px;
$tablet-width: 768px;
$tablet-p-width: 991px;
$desktop-width: 1025px;
$desktop-1200: 1200px;

// iPhone5 Mixin
@mixin iphone5 {
  @media (max-width: #{$iphone5-width}) {
    @content;
  }
}

// iPhone6 Mixin
@mixin iphone6 {
  @media (min-width: #{$iphone6-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

// Mobile Mixin
@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

// Tablet (L/P) Mixin
@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

// Tablet (P) Mixin
@mixin tablet-p {
  @media (min-width: #{$tablet-width}) and (max-width: #{$tablet-p-width}) {
    @content;
  }
}

// Tablet (L) Mixin
@mixin tablet-l {
  @media (min-width: #{$tablet-p-width + 1px}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

// Tablet & Mobile Moxin
@mixin mobile-tablet {
  @media (max-width: #{$tablet-p-width}) {
    @content;
  }
}

// Tablet & Desktop Mixin
@mixin tablet-desktop {
  @media (min-width: #{$tablet-p-width + 1px}) {
    @content;
  }
}

// Desktop Mixin
@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

// ----------------------------------
// Media Query Mixin
// ----------------------------------
$sm-width: 576px;
$md-width: 768px;
$lg-width: 992px;
$xl-width: 1200px;

@mixin small-xs {
  @media (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$sm-width}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$md-width}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$lg-width}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$xl-width}) {
    @content;
  }
}

@mixin md-max {
  @media (max-width: #{$lg-width}) {
    @content;
  }
}
