$color: rgba(0, 0, 0, 0);

.hero-section {
  .hero-slide {
    min-height: 85vh;
    background-size: cover;
    background-position: center;
  }

  .circle {
    border-radius: 50%;
    box-shadow: 60px -60px 0 2px $color, -60px -60px 0 2px $color,
      -60px 60px 0 2px $color, 60px 60px 0 2px $color, 0 0 0 2px $color;

    &:hover {
      animation: border 0.4s ease 1 forwards;
      cursor: none;
    }
  }

  .hero-header {
    text-shadow: 2px 2px 3px rgba(33, 33, 33, 0.5);
  }

  .hero-subheader {
    text-shadow: 2px 2px 3px rgba(33, 33, 33, 0.5);
  }

  .hero-btn {
    text-shadow: 1px 1px 3px rgba(33, 33, 33, 0.5);
  }
}

@keyframes border {
  0% {
    box-shadow: 60px -60px 0 2px $color, -60px -60px 0 2px $color,
      -60px 60px 0 2px $color, 60px 60px 0 2px $color, 0 0 0 2px $color;
  }
  25% {
    box-shadow: 0 -125px 0 2px $color, -60px -60px 0 2px $color,
      -60px 60px 0 2px $color, 60px 60px 0 2px $color, 0 0 0 2px #fff;
  }
  50% {
    box-shadow: 0 -125px 0 2px $color, -125px 0px 0 2px $color,
      -60px 60px 0 2px $color, 60px 60px 0 2px $color, 0 0 0 2px #fff;
  }
  75% {
    box-shadow: 0 -125px 0 2px $color, -125px 0px 0 2px $color,
      0px 125px 0 2px $color, 60px 60px 0 2px $color, 0 0 0 2px #fff;
  }
  100% {
    box-shadow: 0 -125px 0 2px $color, -125px 0px 0 2px $color,
      0px 125px 0 2px $color, 120px 40px 0 2px $color, 0 0 0 2px #fff;
  }
}
