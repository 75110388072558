// ######################################## //
// ## UTILITY-CLASSES.SCSS ## //
// ######################################## //

// --------------------------------
// Fills
// --------------------------------

.fill-grad1 {
  fill: url(#gradient1);
}
.fill-grad2 {
  fill: url(#gradient2);
}
.fill-grad3 {
  fill: url(#gradient3);
}
.fill-grad4 {
  fill: url(#gradient4);
}
.fill-grad5 {
  fill: url(#gradient5);
}

.fill-white {
  fill: $white;
}
.fill-black {
  fill: $black;
}

.fill-black100 {
  fill: $black-100;
}
.fill-black200 {
  fill: $black-200;
}
.fill-black300 {
  fill: $black-300;
}
.fill-black400 {
  fill: $black-400;
}
.fill-black500 {
  fill: $black-500;
}
.fill-black600 {
  fill: $black-600;
}
.fill-black700 {
  fill: $black-700;
}
.fill-black800 {
  fill: $black-800;
}
.fill-black900 {
  fill: $black-900;
}

.fill-brand100 {
  fill: $brand-100;
}
.fill-brand200 {
  fill: $brand-200;
}
.fill-brand300 {
  fill: $brand-300;
}
.fill-brand400 {
  fill: $brand-400;
}
.fill-brand500 {
  fill: $brand-500;
}
.fill-brand600 {
  fill: $brand-600;
}
.fill-brand700 {
  fill: $brand-700;
}
.fill-brand800 {
  fill: $brand-800;
}
.fill-brand900 {
  fill: $brand-900;
}

.fill-gray100 {
  fill: $gray-100;
}
.fill-gray200 {
  fill: $gray-200;
}
.fill-gray300 {
  fill: $gray-300;
}
.fill-gray400 {
  fill: $gray-400;
}
.fill-gray500 {
  fill: $gray-500;
}
.fill-gray600 {
  fill: $gray-600;
}
.fill-gray700 {
  fill: $gray-700;
}
.fill-gray800 {
  fill: $gray-800;
}
.fill-gray900 {
  fill: $gray-900;
}

.fill-danger100 {
  fill: $danger-100;
}
.fill-danger200 {
  fill: $danger-200;
}
.fill-danger300 {
  fill: $danger-300;
}
.fill-danger400 {
  fill: $danger-400;
}
.fill-danger500 {
  fill: $danger-500;
}
.fill-danger600 {
  fill: $danger-600;
}
.fill-danger700 {
  fill: $danger-700;
}
.fill-danger800 {
  fill: $danger-800;
}
.fill-danger900 {
  fill: $danger-900;
}

.fill-info100 {
  fill: $info-100;
}
.fill-info200 {
  fill: $info-200;
}
.fill-info300 {
  fill: $info-300;
}
.fill-info400 {
  fill: $info-400;
}
.fill-info500 {
  fill: $info-500;
}
.fill-info600 {
  fill: $info-600;
}
.fill-info700 {
  fill: $info-700;
}
.fill-info800 {
  fill: $info-800;
}
.fill-info900 {
  fill: $info-900;
}

.fill-success100 {
  fill: $success-100;
}
.fill-success200 {
  fill: $success-200;
}
.fill-success300 {
  fill: $success-300;
}
.fill-success400 {
  fill: $success-400;
}
.fill-success500 {
  fill: $success-500;
}
.fill-success600 {
  fill: $success-600;
}
.fill-success700 {
  fill: $success-700;
}
.fill-success800 {
  fill: $success-800;
}
.fill-success900 {
  fill: $success-900;
}

.fill-warning100 {
  fill: $warning-100;
}
.fill-warning200 {
  fill: $warning-200;
}
.fill-warning300 {
  fill: $warning-300;
}
.fill-warning400 {
  fill: $warning-400;
}
.fill-warning500 {
  fill: $warning-500;
}
.fill-warning600 {
  fill: $warning-600;
}
.fill-warning700 {
  fill: $warning-700;
}
.fill-warning800 {
  fill: $warning-800;
}
.fill-warning900 {
  fill: $warning-900;
}

// --------------------------------
// Background
// --------------------------------

.bg-white {
  background: #fff !important;
}
.bg-black {
  background: $brand-700 !important;
}
.bg-transparent {
  background: transparent !important;
}

.bg-black900 {
  background: $black-900 !important;
}
.bg-black800 {
  background: $black-800 !important;
}
.bg-black700 {
  background: $black-700 !important;
}
.bg-black600 {
  background: $black-600 !important;
}
.bg-black500 {
  background: $black-500 !important;
}
.bg-black400 {
  background: $black-400 !important;
}
.bg-black300 {
  background: $black-300 !important;
}
.bg-black200 {
  background: $black-200 !important;
}
.bg-black100 {
  background: $black-100 !important;
}

.bg-gray900 {
  background: $gray-900 !important;
}
.bg-gray800 {
  background: $gray-800 !important;
}
.bg-gray700 {
  background: $gray-700 !important;
}
.bg-gray600 {
  background: $gray-600 !important;
}
.bg-gray500 {
  background: $gray-500 !important;
}
.bg-gray400 {
  background: $gray-400 !important;
}
.bg-gray300 {
  background: $gray-300 !important;
}
.bg-gray200 {
  background: $gray-200 !important;
}
.bg-gray100 {
  background: $gray-100 !important;
}

.bg-brand900 {
  background: $brand-900 !important;
}
.bg-brand800 {
  background: $brand-800 !important;
}
.bg-brand700 {
  background: $brand-700 !important;
}
.bg-brand600 {
  background: $brand-600 !important;
}
.bg-brand500 {
  background: $brand-500 !important;
}
.bg-brand400 {
  background: $brand-400 !important;
}
.bg-brand300 {
  background: $brand-300 !important;
}
.bg-brand200 {
  background: $brand-200 !important;
}
.bg-brand100 {
  background: $brand-100 !important;
}

.bg-info900 {
  background: $info-900 !important;
}
.bg-info800 {
  background: $info-800 !important;
}
.bg-info700 {
  background: $info-700 !important;
}
.bg-info600 {
  background: $info-600 !important;
}
.bg-info500 {
  background: $info-500 !important;
}
.bg-info400 {
  background: $info-400 !important;
}
.bg-info300 {
  background: $info-300 !important;
}
.bg-info200 {
  background: $info-200 !important;
}
.bg-info100 {
  background: $info-100 !important;
}

.bg-danger900 {
  background: $danger-900 !important;
}
.bg-danger800 {
  background: $danger-800 !important;
}
.bg-danger700 {
  background: $danger-700 !important;
}
.bg-danger600 {
  background: $danger-600 !important;
}
.bg-danger500 {
  background: $danger-500 !important;
}
.bg-danger400 {
  background: $danger-400 !important;
}
.bg-danger300 {
  background: $danger-300 !important;
}
.bg-danger200 {
  background: $danger-200 !important;
}
.bg-danger100 {
  background: $danger-100 !important;
}

.bg-success900 {
  background: $success-900 !important;
}
.bg-success800 {
  background: $success-800 !important;
}
.bg-success700 {
  background: $success-700 !important;
}
.bg-success600 {
  background: $success-600 !important;
}
.bg-success500 {
  background: $success-500 !important;
}
.bg-success400 {
  background: $success-400 !important;
}
.bg-success300 {
  background: $success-300 !important;
}
.bg-success200 {
  background: $success-200 !important;
}
.bg-success100 {
  background: $success-100 !important;
}

.bg-warning900 {
  background: $warning-900 !important;
}
.bg-warning800 {
  background: $warning-800 !important;
}
.bg-warning700 {
  background: $warning-700 !important;
}
.bg-warning600 {
  background: $warning-600 !important;
}
.bg-warning500 {
  background: $warning-500 !important;
}
.bg-warning400 {
  background: $warning-400 !important;
}
.bg-warning300 {
  background: $warning-300 !important;
}
.bg-warning200 {
  background: $warning-200 !important;
}
.bg-warning100 {
  background: $warning-100 !important;
}

.bg-grad1 {
  @include linear-gradient(to bottom, #1285ff, #1171d0);
}
.bg-grad2 {
  @include linear-gradient(to bottom, #ff6b8e, #f14043);
}
.bg-grad3 {
  @include linear-gradient(to bottom, #68d88e, #00d5be);
}
.bg-grad4 {
  @include linear-gradient(to bottom, #916bff, #3366ff);
}
.bg-grad5 {
  @include linear-gradient(to bottom, #d438ff, #8c58ff);
}
.bg-darkbottom {
  @include linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}
.bg-darktop {
  @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
.bg-lightbottom {
  @include linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}
.bg-lighttop {
  @include linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}

// --------------------------------
// Font Color
// --------------------------------

.font-color-black {
  color: $black !important;
}
.font-color-white {
  color: $white !important;
}
.font-color-dark {
  color: $black-900 !important;
}
.font-color-medium {
  color: $gray-900 !important;
}
.font-color-light {
  color: $gray-700 !important;
}
.font-color-disable {
  color: $gray-400 !important;
}
.font-color-brand {
  color: $brand-700 !important;
}
.font-color-info {
  color: $info-800 !important;
}
.font-color-danger {
  color: $danger-800 !important;
}
.font-color-success {
  color: $success-800 !important;
}
.font-color-warning {
  color: $warning-800 !important;
}

//---------------------------
// Outlines
//----------------------------

.outline-black {
  outline: 5px auto $black !important;
}
.outline-white {
  outline: 5px auto $white !important;
}
.outline-dark {
  outline: 5px auto $black-900 !important;
}
.outline-medium {
  outline: 5px auto $black-400 !important;
}
.outline-light {
  outline: 5px auto lighten($black-100, 10%) !important;
}
.outline-disable {
  outline: 5px auto $gray-600 !important;
}
.outline-brand {
  outline: 5px auto $brand-900 !important;
}
.outline-info {
  outline: 5px auto $info-800 !important;
}
.outline-danger {
  outline: 5px auto $danger-800 !important;
}
.outline-success {
  outline: 5px auto $success-800 !important;
}
.outline-warning {
  outline: 5px auto $warning-900 !important;
}
.outline-none {
  outline: none !important;
}

// --------------------------------
// Font Size
// --------------------------------

.font-size-tiny {
  font-size: 0.6875rem;
  line-height: 1rem;
}
.font-size-overline {
  font-size: 0.6875rem;
  line-height: 1rem;
  text-transform: uppercase;
}
.font-size-caption {
  font-size: 0.75rem;
  line-height: 1.25rem;
}
.font-size-body {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.font-size-paragraph {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.font-size-subheader {
  font-size: 1rem;
  line-height: 1.875rem;
}
.font-size-title {
  font-size: 1.25rem;
  line-height: 2rem;
}
.font-size-heading {
  font-size: 1.5rem;
  line-height: 2.25rem;
}
.font-size-display1 {
  font-size: 1.75rem;
  line-height: 2.5rem;
}
.font-size-display2 {
  font-size: 2.125rem;
  line-height: 3rem;
}
.font-size-display3 {
  font-size: 2.5rem;
  line-height: 3.5rem;
}
.font-size-display5 {
  font-size: 4rem;
  line-height: 4.5rem;
}

// --------------------------------
// Font Weight
// --------------------------------

.font-weight-bold {
  font-weight: $bold-weight !important;
}
.font-weight-semibold {
  font-weight: $semibold-weight !important;
}
.font-weight-medium {
  font-weight: $medium-weight !important;
}
.font-weight-regular {
  font-weight: $regular-weight !important;
}
.font-weight-light {
  font-weight: $light-weight !important;
}

// --------------------------------
// Border
// --------------------------------

.border-none {
  border: none !important;
}
.border {
  border: 1px solid transparent;
}
.borderbottom {
  border-bottom: 1px solid transparent;
}
.bordertop {
  border-top: 1px solid transparent;
}
.borderleft {
  border-left: 1px solid transparent;
}
.borderright {
  border-right: 1px solid transparent;
}
.border-md-right {
  @include md {
    border-right: 1px solid;
  }
}
.border-md-bottom {
  @include md {
    border-bottom: 1px solid;
  }
}
.border-width-2 {
  border-width: 2px !important;
}
.border-style-dashed {
  border-style: dashed !important;
}

// --------------------------------
// Border Color
// --------------------------------

.border-color-black {
  border-color: $black !important;
}
.border-color-white {
  border-color: $white !important;
}

.border-color-black900 {
  border-color: $black-900 !important;
}
.border-color-black800 {
  border-color: $black-800 !important;
}
.border-color-black700 {
  border-color: $black-700 !important;
}
.border-color-black600 {
  border-color: $black-600 !important;
}
.border-color-black500 {
  border-color: $black-500 !important;
}
.border-color-black400 {
  border-color: $black-400 !important;
}
.border-color-black300 {
  border-color: $black-300 !important;
}
.border-color-black200 {
  border-color: $black-200 !important;
}
.border-color-black100 {
  border-color: $black-100 !important;
}

.border-color-gray900 {
  border-color: $gray-900 !important;
}
.border-color-gray800 {
  border-color: $gray-800 !important;
}
.border-color-gray700 {
  border-color: $gray-700 !important;
}
.border-color-gray600 {
  border-color: $gray-600 !important;
}
.border-color-gray500 {
  border-color: $gray-500 !important;
}
.border-color-gray400 {
  border-color: $gray-400 !important;
}
.border-color-gray300 {
  border-color: $gray-300 !important;
}
.border-color-gray200 {
  border-color: $gray-200 !important;
}
.border-color-gray100 {
  border-color: $gray-100 !important;
}

.border-color-brand900 {
  border-color: $brand-900 !important;
}
.border-color-brand800 {
  border-color: $brand-800 !important;
}
.border-color-brand700 {
  border-color: $brand-700 !important;
}
.border-color-brand600 {
  border-color: $brand-600 !important;
}
.border-color-brand500 {
  border-color: $brand-500 !important;
}
.border-color-brand400 {
  border-color: $brand-400 !important;
}
.border-color-brand300 {
  border-color: $brand-300 !important;
}
.border-color-brand200 {
  border-color: $brand-200 !important;
}
.border-color-brand100 {
  border-color: $brand-100 !important;
}

.border-color-info900 {
  border-color: $info-900 !important;
}
.border-color-info800 {
  border-color: $info-800 !important;
}
.border-color-info700 {
  border-color: $info-700 !important;
}
.border-color-info600 {
  border-color: $info-600 !important;
}
.border-color-info500 {
  border-color: $info-500 !important;
}
.border-color-info400 {
  border-color: $info-400 !important;
}
.border-color-info300 {
  border-color: $info-300 !important;
}
.border-color-info200 {
  border-color: $info-200 !important;
}
.border-color-info100 {
  border-color: $info-100 !important;
}

.border-color-danger900 {
  border-color: $danger-900 !important;
}
.border-color-danger800 {
  border-color: $danger-800 !important;
}
.border-color-danger700 {
  border-color: $danger-700 !important;
}
.border-color-danger600 {
  border-color: $danger-600 !important;
}
.border-color-danger500 {
  border-color: $danger-500 !important;
}
.border-color-danger400 {
  border-color: $danger-400 !important;
}
.border-color-danger300 {
  border-color: $danger-300 !important;
}
.border-color-danger200 {
  border-color: $danger-200 !important;
}
.border-color-danger100 {
  border-color: $danger-100 !important;
}

.border-color-success900 {
  border-color: $success-900 !important;
}
.border-color-success800 {
  border-color: $success-800 !important;
}
.border-color-success700 {
  border-color: $success-700 !important;
}
.border-color-success600 {
  border-color: $success-600 !important;
}
.border-color-success500 {
  border-color: $success-500 !important;
}
.border-color-success400 {
  border-color: $success-400 !important;
}
.border-color-success300 {
  border-color: $success-300 !important;
}
.border-color-success200 {
  border-color: $success-200 !important;
}
.border-color-success100 {
  border-color: $success-100 !important;
}

.border-color-warning900 {
  border-color: $warning-900 !important;
}
.border-color-warning800 {
  border-color: $warning-800 !important;
}
.border-color-warning700 {
  border-color: $warning-700 !important;
}
.border-color-warning600 {
  border-color: $warning-600 !important;
}
.border-color-warning500 {
  border-color: $warning-500 !important;
}
.border-color-warning400 {
  border-color: $warning-400 !important;
}
.border-color-warning300 {
  border-color: $warning-300 !important;
}
.border-color-warning200 {
  border-color: $warning-200 !important;
}
.border-color-warning100 {
  border-color: $warning-100 !important;
}

// --------------------------------
// Border Radius
// --------------------------------

.rounded-circle {
  border-radius: 100rem !important;
}
.rounded-lg {
  border-radius: 0.5rem !important;
}
.rounded-sm {
  border-radius: 0.25rem !important;
}
.rounded-xs {
  border-radius: 0.125rem !important;
}
.rounded-0 {
  border-radius: 0rem !important;
}
.rounded-left-circle {
  border-radius: 100rem 0 0 100rem !important;
}
.rounded-left-lg {
  border-radius: 0.5rem 0 0 0.5rem !important;
}
.rounded-left-sm {
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.rounded-left-xs {
  border-radius: 0.125rem 0 0 0.125rem !important;
}
.rounded-left-0 {
  border-radius: 0 auto auto 0 !important;
}
.rounded-right-circle {
  border-radius: 0 100rem 100rem 0 !important;
}
.rounded-right-lg {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}
.rounded-right-sm {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.rounded-right-xs {
  border-radius: 0 0.125rem 0.125rem 0 !important;
}
.rounded-right-0 {
  border-radius: auto 0 0 auto !important;
}
.rounded-top-circle {
  border-radius: 100rem 100rem 0 0 !important;
}
.rounded-top-lg {
  border-radius: 0.5rem 0.5rem 0 0 !important;
}
.rounded-top-sm {
  border-radius: 0.25rem 0.25rem 0 0 !important;
}
.rounded-top-xs {
  border-radius: 0.125rem 0.125rem 0 0 !important;
}
.rounded-top-0 {
  border-radius: 0 0 auto auto !important;
}
.rounded-bottom-circle {
  border-radius: 0 0 100rem 100rem !important;
}
.rounded-bottom-lg {
  border-radius: 0 0 0.5rem 0.5rem !important;
}
.rounded-bottom-sm {
  border-radius: 0 0 0.25rem 0.25rem !important;
}
.rounded-bottom-xs {
  border-radius: 0 0 0.125rem 0.125rem !important;
}
.rounded-bottom-0 {
  border-radius: auto auto 0 0 !important;
}

// --------------------------------
// Shadow
// --------------------------------

.shadow-1 {
  @include box-shadow(
    0,
    0,
    1px,
    0,
    rgba($black-900, 0.06),
    0,
    1px,
    1px,
    0,
    rgba($black-900, 0.1)
  );
}
.shadow-2 {
  @include box-shadow(
    0,
    0,
    1px,
    0,
    rgba($black-900, 0.06),
    0,
    3px,
    3px,
    -1px,
    rgba($black-900, 0.1)
  );
}
.shadow-3 {
  @include box-shadow(
    0,
    0,
    1px,
    0,
    rgba($black-900, 0.06),
    0,
    6px,
    6px,
    -1px,
    rgba($black-900, 0.1)
  );
}
.shadow-4 {
  @include box-shadow(
    0,
    0,
    1px,
    0,
    rgba($black-900, 0.06),
    0,
    16px,
    16px,
    -1px,
    rgba($black-900, 0.1)
  );
}
.shadow-5 {
  @include box-shadow(
    0,
    0,
    1px,
    0,
    rgba($black-900, 0.06),
    0,
    32px,
    40px,
    -1px,
    rgba($black-900, 0.1)
  );
}

.img-shadow {
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 16px 16px);
}

// --------------------------------
// Height & Width
// --------------------------------

.h-100vh {
  height: 100vh;
}
// .h-100 { height: 100% }
.h-72 {
  height: 4.5rem;
}
.h-64 {
  height: 4rem;
}
.h-56 {
  height: 3.5rem;
}
.h-48 {
  height: 3rem;
}
.h-40 {
  height: 2.5rem;
}
.h-32 {
  height: 2rem;
}
.h-24 {
  height: 1.5rem;
}
.h-20 {
  height: 1.25rem;
}
.h-16 {
  height: 1rem;
}
.h-12 {
  height: 0.75rem;
}
.h-8 {
  height: 0.5rem;
}
.h-4 {
  height: 0.25rem;
}
.h-1 {
  height: 1px;
}
.h-0 {
  height: 0;
}
// .h-auto { height: auto }
// .h-fit-content { height: fit-content }

// .w-100 { width: 100% }
.w-64 {
  width: 4rem;
}
.w-56 {
  width: 3.5rem;
}
.w-48 {
  width: 3rem;
}
.w-40 {
  width: 2.5rem;
}
.w-32 {
  width: 2rem;
}
.w-24 {
  width: 1.5rem;
}
.w-20 {
  width: 1.25rem;
}
.w-16 {
  width: 1rem;
}
.w-12 {
  width: 0.75rem;
}
.w-8 {
  width: 0.5rem;
}
.w-4 {
  width: 0.25rem;
}
.w-0 {
  width: 0;
}
// .w-auto { width: auto }
// .w-fit-content { width: fit-content }

.mw-1600 {
  max-width: 1600px;
}

// --------------------------------
// Translate
// --------------------------------

.translateX--50 {
  @include translate(-50%, 0);
}
.translateY--50 {
  @include translate(0, -50%);
}
.translateX-50 {
  @include translate(50%, 0);
}
.translateY-50 {
  @include translate(0, 50%);
}
.translate-50-50 {
  @include translate(50%, 50%);
}
.translate--50--50 {
  @include translate(-50%, -50%);
}
.translate-50--50 {
  @include translate(50%, -50%);
}
.translate--50-50 {
  @include translate(-50%, 50%);
}

//---------------------------
// Positions
//----------------------------

.top-0 {
  top: 0;
}
.top-50 {
  top: 50%;
}
.top-100 {
  top: 100%;
}

.left-0 {
  left: 0;
}
.left-50 {
  left: 50%;
}
.left-100 {
  left: 100%;
}

.right-0 {
  right: 0;
}
.right-50 {
  right: 50%;
}
.right-100 {
  right: 100%;
}

.bottom-0 {
  bottom: 0;
}
.bottom-50 {
  bottom: 50%;
}
.bottom-100 {
  bottom: 100%;
}

//---------------------------
// Opacity
//----------------------------

.opacity-0 {
  opacity: 0;
}
.opacity-16 {
  opacity: 0.16;
}
.opacity-24 {
  opacity: 0.24;
}
.opacity-32 {
  opacity: 0.32;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-56 {
  opacity: 0.56;
}
.opacity-64 {
  opacity: 0.64;
}

// //---------------------------
// // Cursors
// //----------------------------

.cursor-pointer {
  cursor: pointer;
}
// .cursor-zoom-in {
//   cursor: zoom-in;
// }

//---------------------------
// Extras
//----------------------------

.transition {
  @include transition(all, 1s, cubic-bezier(0.2, 0.01, 0.45, 1));
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//---------------------------
// Text Decoration
//----------------------------

.text-decoration-underline {
  text-decoration: underline;
}

//---------------------------
// Font Family
//----------------------------

.font-family-secondary {
  font-family: "Playfair Display", serif;
}

// ----------------------------
// Text Transform
// ----------------------------

.text-transform-uppercase {
  text-transform: uppercase;
}

// ----------------------------
// Extended
// ----------------------------

.position-sm-absolute {
  @include sm {
    position: absolute;
  }
}

.position-md-absolute {
  @include md {
    position: absolute;
  }
}

.position-sm-fixed {
  @include sm {
    position: fixed;
  }
}

//// ----------------------------
// Object fit
// ----------------------------

.object-cover {
  object-fit: cover;
}

@media print {
  .no-print {
    display: none;
  }
}

//// ----------------------------
// Margin
// ----------------------------

.mt-6,
.my-6 {
  margin-top: 2rem;
}